@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
     .nav-item::after{
          content:"";
          width:0;
          height:2px;
          background: #033245;
          position:absolute;
          bottom:0;
          left:0;
          transition:all 0.8s ease;
          -webkit-transition:all 0.8s ease;
          -moz-transition:all 0.8s ease;
          -ms-transition:all 0.8s ease;
          -o-transition:all 0.8s ease;
}
  .nav-item:hover::after{
     width:100%;
  }
  .nav-item.active::after{
     width:100%;
  }
    .testimonial-btn:before{
         position:absolute;
         content:"";
         width: 10px;
         height:10px;
         background:rgba(21,74,117, 0.2);
         top:50%;
         left:50%;
         transform:translate(-47%, -54%)    ;
         -webkit-transform:translate(-47%, -54%)    ;
         -moz-transform:translate(-47%, -54%)    ;
         -ms-transform:translate(-47%, -54%)    ;
         -o-transform:translate(-47%, -54%)    ;
         border-radius:50%   ;
         -webkit-border-radius:50%   ;
         -moz-border-radius:50%   ;
         -ms-border-radius:50%   ;
         -o-border-radius:50%   ;
         transition:background 0.5s ease;
         -webkit-transition:background 0.5s ease;
         -moz-transition:background 0.5s ease;
         -ms-transition:background 0.5s ease;
         -o-transition:background 0.5s ease;
    }
    .testimonial-btn:hover::before{
         background:rgb(21, 74, 117);
    }
   .testimonial-btn.activated::before{
       background:rgb(21, 74, 117);
   }
}